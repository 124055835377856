/*
/App

The main entry point for application components.
*/

import './App.scss';
import './App.css';
// common modules
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import _ from 'lodash';
import { useIntercom } from 'react-use-intercom';

// custom modules
import { reactPlugin, appInsights } from '@utilities/applicationInsights.js';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import { getRoutes } from '@utilities/routes';
import TopBar from '@components/header/topBar';
import HeaderMenu from '@components/header/headerMenu';
import HeaderImage from '@components/header/headerImage';
import AppFooter from '@components/footer';
import ProgressDialog from '@components/dialog/progressDialog';
import CustomDialog from '@components/dialog/customDialog';

import api, { initRoles, hasRole, scopes, getEmail } from '@utilities/claApi';
import { authenticated, getAccount, injectLocalDevelopmentTokens } from '@utilities/authentication';
import { loadVehicleData } from '@utilities/populatePriorData/vehicles/populateVehicleData.js';
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';
import { setRequiredForms } from '@utilities/helpers/setRequiredForms';
import lastUserActivityDate, { lastUserActivityDateSetter } from '@utilities/helpers/lastUserActivityDate';
import { LicenseInfo } from '@mui/x-license-pro';
import UploadWarningDialog from '@components/dialog/uploadWarningDialog';
import ShowLastFormSave from '@components/showLastFormSave';
import { calculateDashboardSummary } from '@utilities/helpers/calculateDashboardSummary.js';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_KEY);

//redirect to AAD login for sso, call when unauthenticated
const RedirectToLogin = () => {
	const { instance, inProgress } = useMsal();

	// when interaction is complete, redirect to login
	if (inProgress === InteractionStatus.None) {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			// inject development tokens into cache then refresh entire page
			injectLocalDevelopmentTokens();
			window.location.reload();
		} else {
			// redirect to MSAL login
			instance.loginRedirect({ scopes });
		}
	}
};

function App() {
	const {
		selectState,
		REDUX,
		dispatch,
		ACTION,
		setEntityFormState,
		updateUploadList,
	} = usePageFramework();
	const { boot } = useIntercom();

	// Roles
	const isAuthenicated = selectState(REDUX.IS_AUTHENTICATED);
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
	const isAdmin = selectState(REDUX.IS_ADMIN);
	const isClient = selectState(REDUX.IS_CLIENT);

	const activeReturn = selectState(REDUX.ACTIVE_RETURN);

	// Progress Dialog
	const isProgressVisible = selectState(REDUX.PROGRESS_VISIBLE);
	const progressText = selectState(REDUX.PROGRESS_TEXT);

	// Custom Dialog
	const showCustomDialog = selectState(REDUX.SHOW_CUSTOM_DIALOG);

	//Upload Warning Dialog
	const isUploadWarningVisible = selectState(REDUX.UPLOAD_WARNING_VISIBLE);
	const dupFiles = selectState(REDUX.DUPLICATE_UPLOAD_FILES) || [];
	const uploadProps = selectState(REDUX.UPLOADS_PROPS);
	const uploadList = selectState(REDUX.UPLOAD_LIST);

	const availableRoutes = getRoutes(isAuthenicated, isPractitioner, isAdmin, isClient, activeReturn).reduce((prev, page) => {
		prev.push(<Route path={page.to} exact component={page.componentObject} key={page.to} />);
		return prev;
	}, []);

	lastUserActivityDateSetter.lastUserActivityDate = lastUserActivityDate(selectState);

	// GROWTH: Handle loading data with multiple client numbers
	useEffect(() => {
		async function initialize() {
			let isAdmin = false;
			let isPractitioner = false;
			let isClient = false;
			let email = '';

			// if authenticated, load app
			if (authenticated()) {
				const account = getAccount();

				// GROWTH: Would this be more appropriate to have following sign in?
				if (account) {
					// set Authenticated User Context for AppInsights
					appInsights.setAuthenticatedUserContext(
						account.username ?
							account.username : account.idTokenClaims.email);

					// boot intercom as current user
					boot({
						name: account.name,
						email: account.username ? account.username : account.idTokenClaims.email,
						hideDefaultLauncher: true
					});
				}

				try {
					await initRoles();

										// determine user roles and store in state
					isAdmin = hasRole(['Administrator']) ?? false;
					isPractitioner = (!hasRole(['Administrator']) && hasRole(['Employee'])) ?? false;
					isClient = (!hasRole(['Administrator']) && !hasRole(['Employee']) && hasRole(['Client'])) ?? false;
					email = getEmail();

					// TODO Growth: we can combine these actions into a single action. Something like setUserRoles. Combining the actions (and therefore lessening the number of them) will result in better self-documenting redux reducers.
					dispatch(ACTION.setIsAdmin(isAdmin));
					dispatch(ACTION.setIsPractitioner(isPractitioner));
					dispatch(ACTION.setIsClient(isClient));
					dispatch(ACTION.setAuthUserEmail(email));
					
					const limit = 50;
					if (isClient) {
						// TODO Growth: since we know we want this to be false initially, we can initialize the reducer state as 'false' and no longer need to dispatch this action (self documentation)
						// when client first login, set false, so modal will pop
						dispatch(ACTION.setHasSkipEntryStep(false));
						// load organizers
						const { data: { results: organizers } } = await api.get(`/organizers?limit=${limit}`)
		
						if (organizers.length === 0) return;

						const organizer = organizers.find((org) => org.year === currentYear);
						
						let priorYearByClient = {};
						organizers.forEach(organizer => {
							if (!priorYearByClient[organizer?.client?.number]) {
								priorYearByClient[organizer?.client?.number] = [{ name: organizer.year, value: organizer.year, id: organizer.id }];
							} else {
								priorYearByClient[organizer?.client?.number].push({ name: organizer.year, value: organizer.year, id: organizer.id });
							}
						})

						let priorOrganizer = organizers.find(obj => 
							obj.client.number === organizer?.client?.number &&
							obj.year === organizer.year - 1 &&
							obj.id !== organizer.id
						);

						if (priorOrganizer) {
							dispatch(ACTION.setPriorYearDetails({ priorYear: priorOrganizer.year, priorOrganizerId: priorOrganizer.id, priorYearLookup: priorYearByClient[organizer?.client?.number] }));
						} else {
							dispatch(ACTION.setPriorYearDetails({ priorYear: false, priorOrganizerId: undefined, priorYearLookup: priorYearByClient[organizer?.client?.number] }));
						}


						if (!organizer) return;

						// get full organizer details
						const { data: organizerDoc } = await api.get(`/organizers/${organizer.id}`)
								
						if (!organizerDoc) return;
							
						const {
							id,
							forms,
							locked,
							client,
							status,
							year,
							entryExperience,
							firstLogIn,
							spouse
							//TODO Growth: will add taxpayer property here too in the future
						} = organizerDoc;

						// TODO Growth: we should decide whether or not we care about errors here. If we do, we should do something other than log them to the console. If we don't, we should ignore them. 
						// Example: if there is no priorYear data, it does not seem that we care here. Rather than put something in the console, we could just let the error go. (Seems similar with Notes)
						const [dashboardResponse, notesResponse, priorYearResponse] = await Promise.all([
							api.get(`organizers/${id}/dashboard`).catch(err => console.log('Dashboard error: ', err)),
							api.get(`/organizers/${id}/notes`).catch(err => console.log('Notes error: ', err)),
							api.get(`/organizers/${id}/prior`).catch(err => console.log('Prior Year error: ', err)),
							updateUploadList(id),
						]);

						const { id: dashboardId, dashboard } = dashboardResponse?.data || {};

						let hasVehicleData = false;

						if (spouse) {
							dispatch(ACTION.setSpouseEmail(spouse.email || ''))
						}

						const notes = notesResponse?.data.results
						dispatch(ACTION.setFormNotes(notes));
								
						// get prior year data for organizer
						const priorYearData = priorYearResponse?.data.data.taxData
						dispatch(ACTION.setPriorYearData(priorYearData?.priorYear));

								// store organizer details in state
						if (dashboard) {
							dispatch(ACTION.setLastSaveFormTime(dashboard?.[0]?.cards?.[0].lastFormSavedOn || null));
							dispatch(ACTION.setDashboard(dashboard));
						
						} else {
							// create and save new dashboard bard on prior year copy
							const dashCopy = setRequiredForms(priorYearData?.priorYear || [], _.cloneDeep(dashboard || defaultDashboard));
							const { summary } = await calculateDashboardSummary(dashboardId, dashCopy, organizer);
						
							await Promise.all([
								api.put(`/organizers/${id ?? ''}`, { lastUserActivityOn: new Date(), dashboardSummary: summary })
									.catch(err => console.log("Unable to update organizer:\n", err)),
								api.put(`/organizers/${id ?? ''}/dashboard/${dashboardId}`, { dashboard: dashCopy })
									.catch(err => console.log('Unable to update dashboard:\n', err)),
							]);

							dispatch(ACTION.setDashboard(dashCopy));
						}

						//logs a date and time for when the client first logs into an organizer and if the organizer is from the current tax year
						if (!firstLogIn) {
							const data = { firstLogIn: new Date() };
							await api.put(`/organizers/${id ?? ''}`, data)
						}
						
						const formKeys = [];
						forms?.forEach((form) => {
							if (!formKeys.includes(form.key)) {
								dispatch(ACTION.setForm(form.key, form.data));
								hasVehicleData = hasVehicleData || form.key.startsWith(REDUX.VEHICLE);
								formKeys.push(form.key);
							}
						});

						if (priorYearData && priorYearData.priorYear && !hasVehicleData) {
							const vehicleFormData = loadVehicleData(priorYearData.priorYear, year);
							if (vehicleFormData && Object.keys(vehicleFormData).length) {
								Object.entries(vehicleFormData).forEach(([vehicleKey, vehicleForm]) => {
									setEntityFormState(vehicleKey, vehicleForm, id);
								});
							}
						}

						// TODO Growth: these actions should also be combined / trimmed down
						// Set organizer metadata
						dispatch(ACTION.setActiveReturn({
							displayName: client.name,
							clientNumber: client?.number ?? '',
							formStatus: status,
							currentYear: year
						}));
						dispatch(ACTION.setOrganizerId(id));
						dispatch(ACTION.setYear(year));
						dispatch(ACTION.setLockForms(locked || false));
						dispatch(ACTION.setIsSaveSuccess(id ? true : false));
						dispatch(ACTION.setCompletedEntryStep(entryExperience?.completedStep > 0));
					}

					if (isAdmin || isPractitioner) {
						// Load locking permissions for the Exchange Manager
						const { data: { userLockPermission } } = await api.get('/users/me')
						dispatch(ACTION.setLockingPermission(userLockPermission || false));
						
					}

				} catch (err) {
					if (!isAdmin && !isPractitioner) {
						console.error('Contact admin to get set up');
					}
					console.error('Err: ', err);
				} finally {
					dispatch(ACTION.setisAuthenticated(authenticated() ?? false));
				}
			}
		}

		initialize()
		//eslint-disable-next-line
	}, []);

	return (
		<div className='App'>
			<TopBar />
			<HeaderMenu />
			<HeaderImage />
			<>
				<AuthenticatedTemplate>
					<Switch>
						{availableRoutes}
					</Switch>
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<RedirectToLogin />
				</UnauthenticatedTemplate>
				<ShowLastFormSave className='lastSaveMsgFt' isNavBar = {true} />
			</>
			<AppFooter />
			<ProgressDialog visible={isProgressVisible ?? false} loadingText={progressText ?? ''} />
			<CustomDialog visible={showCustomDialog || false} />
			<UploadWarningDialog visible={isUploadWarningVisible || false} dupFiles={dupFiles} uploadProps={uploadProps} uploadList={uploadList} />
		</div>
	);
}

export default withAITracking(reactPlugin, App);